<template>
  <div class="footer">
    <div class="inner">
      <LazyFooterSections
        :key="`${route?.params?.continentid}/${route?.params?.countryid}`"
        openable
      />

      <div class="socials">
        <NuxtLink
          v-for="social of common?.socials"
          :key="social.id"
          :to="social.url"
          class="social"
          target="_blank"
        >
          <Icon v-if="social.icon" :name="social.icon" />
        </NuxtLink>
      </div>

      <div class="section-title">Nos bureaux*</div>
      <FooterOffices centered mobile-squish />

      <div class="conditions">*sur rendez-vous uniquement</div>
    </div>

    <div class="bottom">
      <div class="links-container">
        <div class="links">
          <template v-for="(link, i) of links" :key="link.id">
            <div v-if="i !== 0" class="point" />
            <NuxtLink :href="`${link.url}/`" class="link">{{ link.name }}</NuxtLink>
          </template>
          <div class="link" @click="showConsentPopup()">Paramètres des cookies</div>
        </div>
        <div class="copyright">© {{ new Date().getFullYear() }} MARCO VASCO</div>
      </div>
      <div class="logo-container">
        <mv-logo size="1.1rem" brand="mv" color="black"></mv-logo>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const footerStore = useFooterStore();
const { links } = storeToRefs(footerStore);
const rootStore = useRootStore();
const { common } = storeToRefs(rootStore);

const route = useRoute();

const showConsentPopup = () => {
  if (window.__tcfapi) {
    window.__tcfapi('show', 2, () => {}, { lazy: false });
  } else {
    console.warn('The __tcfapi method is not available.');
  }
};
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';

.footer {
  flex-direction: column;
  margin-bottom: 72px;

  @include breakpoints.mobile() {
    margin-bottom: 56px;
  }

  .inner {
    flex-direction: column;
    gap: 48px;

    padding: 40px;

    color: white;

    background-color: colors.$black;

    @include breakpoints.mobile() {
      padding: 32px metrics.$mobile-inner-margin;
    }

    .socials {
      $border: solid 1px white;

      gap: 24px;
      align-items: center;
      justify-content: center;

      padding: 38px 0;

      border-top: $border;
      border-bottom: $border;

      @include breakpoints.mobile() {
        gap: 28px;
        padding: 56px 0;
      }

      .social {
        @include mouse.clickable-svg($color-base: white);

        display: flex;

        @include breakpoints.mobile() {
          transform: scale(1.2);
        }
      }
    }

    .section-title {
      justify-content: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;

      @include breakpoints.mobile() {
        margin-bottom: -22px;
      }
    }

    .conditions {
      justify-content: flex-end;
      line-height: 19px;

      @include breakpoints.mobile() {
        justify-content: flex-start;
        margin-top: -32px;
      }
    }

    .logo-container {
      display: none;

      @include breakpoints.mobile() {
        display: flex;
        justify-content: center;
        padding-top: 12px;
        padding-bottom: 28px;
      }
    }
  }

  .bottom {
    background-color: colors.$grey-500;

    .links-container {
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px;

      @include breakpoints.mobile() {
        flex-direction: column;
        gap: 18px;
        padding: 18px 16px;
      }

      .link,
      .copyright {
        font-size: 13px;
        line-height: 16px;
      }

      .links {
        display: contents;

        @include breakpoints.mobile() {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: center;
          justify-content: center;
        }

        .point {
          display: none;

          @include breakpoints.mobile() {
            $size: 4px;

            display: flex;
            flex-shrink: 0;

            width: $size;
            height: $size;

            background-color: colors.$primary-black;
            border-radius: 50%;
          }
        }

        .link {
          @include mouse.clickable-lightness-hover();

          @include breakpoints.mobile() {
            flex-shrink: 0;
          }
        }
      }
    }

    .logo-container {
      $width: 254px;
      $width-content: 230px;
      $height: 54px;

      align-items: center;
      justify-content: center;

      width: $width;
      height: $height;

      background-color: white;
      clip-path: polygon($width - $width-content 0, 0 $height, $width $height, $width 0);

      @include breakpoints.mobile() {
        display: none;
      }
    }
  }
}
</style>
