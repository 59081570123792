<template>
  <div class="footer-line" :class="{ squish: mobileSquish, centered }">
    <div v-for="office of offices" :key="office.id" class="office">
      <div class="title-container">
        <div class="title">{{ office.title }}</div>
      </div>
      <div class="title-separator" />
      <div class="container">
        <div class="content">
          <div class="text">{{ office.address }}</div>
          <div class="text">{{ office.phoneNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { mobileSquish = false, centered = false } = defineProps<{
  mobileSquish?: boolean;
  centered?: boolean;
}>();

const footerStore = useFooterStore();
const { offices } = storeToRefs(footerStore);
</script>

<style lang="scss" scoped>
@use '$/animation.scss';
@use '$/breakpoints.scss';

.footer-line {
  justify-content: space-between;

  @include breakpoints.mobile() {
    flex-direction: column;
    gap: 24px;
  }

  &.squish {
    @include breakpoints.mobile() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.centered {
    gap: 8vw;
    justify-content: center;
  }

  .office {
    $section-gap: 18px;

    flex-direction: column;
    gap: $section-gap;
    width: 215px;

    @include breakpoints.mobile() {
      width: initial;
    }

    .title-container {
      display: contents;

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;

        @include breakpoints.mobile() {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    .title-separator {
      border-top: dashed 2px white;
    }

    .container {
      display: contents;
      flex-direction: column;
      gap: 8px;

      .content {
        flex-direction: column;

        .text {
          font-size: 14px;
          line-height: 23px;
          color: white;
          white-space: pre-wrap;

          @include breakpoints.mobile() {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }

  &.squish .section .container {
    @include breakpoints.mobile() {
      margin-top: -5px;
    }

    .text {
      @include breakpoints.mobile() {
        font-size: 12px;
        line-height: 24px;
      }
    }
  }
}
</style>
